import {axiosData} from "@/store/api/axios";

const state = () => ({
  puertas: null || JSON.parse(localStorage.getItem('puertas')),
  puerta: null || JSON.parse(localStorage.getItem('puerta'))
});

const getters = {
  getPuertas(state) {
    return state.puertas;
  },
  getPuerta(state) {
    return state.puerta;
  },
};

const actions = {
  setPuerta({ commit }, payload) {
    commit('SET_PUERTA', payload);
    payload
        ? localStorage.setItem('puerta', JSON.stringify(payload))
        : localStorage.removeItem('puerta');
  },
  setPuertas({ commit }, payload) {
    commit('SET_PUERTAS', payload);
    payload
        ? localStorage.setItem('puertas', JSON.stringify(payload))
        : localStorage.removeItem('puertas');
  },
  async getPuertasNegocio(_,puerta){
    try {
      const data = await axiosData(
          false,
          'puerta/negocio/'+puerta,
          'get',
          false,
          false
      );
      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }


    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
    }
  },
  async getPuerta(_,puerta){
    try {
      const data = await axiosData(
          false,
          'puerta/'+puerta,
          'get',
          false,
          false
      );
      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }


    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Error Comunicacion con Tunay, error: ${error}`);
    }
  },
  async savePuertaNegocio(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'puerta/negocio/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async savePuertaCliente(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'puerta/cliente/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
};

const mutations = {
  SET_PUERTAS(state, payload) {
    state.puertas = payload;
    localStorage.setItem('puertas', JSON.stringify(payload));
  },
  SET_PUERTA(state, payload) {
    state.puerta = payload;
    localStorage.setItem('puerta', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
